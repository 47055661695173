import React, { FC } from 'react';
import ModalScreen from '@zvuk-b2b/react-uikit/ui/ModalScreen';
import { ModalProps, ModalTypes } from 'application/providers/ModalProvider';

import Button, { ButtonType } from '@zvuk-b2b/react-uikit/ui/Button';
import Input, { InputType } from '@zvuk-b2b/react-uikit/ui/Input';
import TextArea from '@zvuk-b2b/react-uikit/ui/TextArea';
import { useNavigate } from 'react-router-dom';
import DraggableVideoMediafiesList from '../DragableVideoMediafiesList';

import styles from './index.module.scss';
import { TEXTS } from '../texts';
import { useCreateVideoStreamController } from './useCreateVideoStreamController';
import { useCreateVideoStreamPresenter } from '../presenters/createVideoStreamPresenter';

const CreateVideoStreamModal: FC<ModalProps[ModalTypes.CREATE_VIDEO_STREAM]> = ({
  openModal,
  close,
  selectedFiles,
  flow,
  title = '',
  description = '',
  videoStreamId = '',
  points = [],
  refetch,
}) => {
  const { createVideoStream, updateVideoStream, companyId } = useCreateVideoStreamPresenter();
  const {
    onSave,
    mediafiles,
    setMediafiles,
    videoStreamTitle,
    setVideoStreamTitle,
    videoStreamDescription,
    setVideoStreamDescription,
    modalTitle,
  } = useCreateVideoStreamController({
    createVideoStream,
    updateVideoStream,
    selectedFiles,
    title,
    description,
    flow,
    videoStreamId,
    points,
    companyId,
  });

  const navigate = useNavigate();

  const onAddFile = async () => {
    await close();
    openModal(ModalTypes.SELECT_VIDEO_MEDIAFILES, {
      openModal,
      prevVideoMediafiles: mediafiles,
      flow,
      title,
      description,
      videoStreamId,
      points,
      refetchSteam: refetch,
    });
  };

  const saveButtonHandler = async () => {
    const newStreamId = await onSave(videoStreamTitle, videoStreamDescription, mediafiles);
    refetch?.();
    await close();

    if (newStreamId) {
      navigate(`/video-streams/${newStreamId}`, { replace: true });
    }
  };

  return (
    <ModalScreen
      titlePlacement="left"
      title={modalTitle}
      contentClassName={styles.modal}
      onClose={close}
      actionButtons={[
        <Button
          key="button_video_mediafies_next"
          elementId="button_video_mediafies_next"
          type={ButtonType.PRIMARY}
          isDisabled={!videoStreamTitle || mediafiles.length === 0}
          onClick={saveButtonHandler}>
          {TEXTS.SAVE}
        </Button>,
      ]}>
      <div className={styles.listWrapper}>
        <DraggableVideoMediafiesList
          itemList={mediafiles}
          updateItems={setMediafiles}
        />
      </div>
      <Button
        onClick={onAddFile}
        elementId="button_add_file"
        type={ButtonType.SECONDARY}>
        {TEXTS.ADD_FILE}
      </Button>
      <Input
        elementId="input_videostream_title"
        label={TEXTS.EDIT_VIDEO_STREAM_TITLE_LABEL}
        type={InputType.TEXT}
        value={videoStreamTitle}
        onChange={setVideoStreamTitle}
        required
      />
      <TextArea
        placeholder={TEXTS.EDIT_VIDEO_STREAM_DESCRIPTION_PLACEHOLDER}
        onChange={setVideoStreamDescription}
        label={TEXTS.EDIT_VIDEO_STREAM_DESCRIPTION_LABEL}
        elementId="input_videostream_description"
        rows={4}
        value={videoStreamDescription}
      />
    </ModalScreen>
  );
};

export default CreateVideoStreamModal;
