import { IVideoMediafile } from '../types';

export const getDefaultVideoStreamInput = (
  title: string,
  description: string,
  videoMediafiles: IVideoMediafile[],
  points: { id: string }[] = []
) => ({
  pointIds: points.map((it) => it.id),
  title,
  description,
  videoMediafiles: videoMediafiles.map((videoMediafile, index) => ({
    mediafileId: videoMediafile.mediafile?.id || videoMediafile.id?.replace(/-.*/, '') || '',
    playDuration: videoMediafile.playDuration,
    title: videoMediafile.title || '',
    order: index + 1,
  })),
});
