import React from 'react';

import Checkbox, { LabelPlacement } from '@zvuk-b2b/react-uikit/ui/Checkbox';
import IconTrash2 from '@zvuk-b2b/react-uikit/ui/icons/Trash2';
import styles from './index.module.scss';
import { IVideoMediafile } from '../../types';

type WrapperType = {
  onChange: (value: boolean, currentItem: IVideoMediafile) => void;
  currentItem?: IVideoMediafile;
  isChecked: boolean;
  children: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
};

export type ChooseItemListItemType = WrapperType & {};

const defaultItem = {
  id: '0',
  title: '',
  previewUrl: '',
  playDuration: 0,
};

export const CheckboxWrapper = ({
  onChange,
  currentItem = defaultItem,
  children,
  isChecked,
  isDisabled,
  isLoading,
}: WrapperType) => (
  <Checkbox
    key={`checkbox_item_bind_${currentItem.id}`}
    elementId={`checkbox_item_bind_${currentItem.id}`}
    isChecked={isChecked}
    isDisabled={isDisabled}
    labelPlacement={LabelPlacement.RIGHT}
    isStretch
    isLoading={isLoading}
    className={styles.ChooseItemListItemCheckbox}
    onChange={(value) => {
      onChange(value, currentItem);
    }}>
    <div className={styles.ChildrenWrapper}>{children}</div>
  </Checkbox>
);

const ChooseItemListItem = ({
  currentItem,
  onChange,
  isChecked,
  children,
  isDisabled = false,
  isLoading = false,
}: ChooseItemListItemType) => (
  <div
    className={styles.ChooseItemListItem}
    key={`item-list_item_${currentItem?.id}`}>
    <CheckboxWrapper
      isChecked={isChecked}
      currentItem={currentItem}
      onChange={(value: boolean) => {
        if (!currentItem) return;
        onChange(value, currentItem);
      }}
      isDisabled={isDisabled}
      isLoading={isLoading}>
      {children}
    </CheckboxWrapper>
    <div className={styles.IconDelete}>
      <IconTrash2 />
    </div>
  </div>
);

export default ChooseItemListItem;
