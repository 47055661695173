import { DEFAULT_POINT_STATUSES } from 'application/pages/PointsPage/renderer/PointsToolbar/consts';
import { useAccountContext } from 'application/providers/AccountProvider';
import useAllPointList from 'domain/point/useAllPointList';
import { useCallback, useEffect } from 'react';

export const usePointsTabPresenter = () => {
  const { companyIds } = useAccountContext();
  const allPointList = useAllPointList({ companyIds, playingStatuses: DEFAULT_POINT_STATUSES, hasVideoSupport: true });

  useEffect(() => {
    if (companyIds.length > 0) {
      allPointList.request();
    }
  }, [companyIds]);

  const loadMore = useCallback(async () => {
    if (allPointList.loading || !allPointList.paginationInfo?.hasNextPage) {
      return;
    }

    allPointList.fetchMore(allPointList.paginationInfo?.page);
  }, [allPointList]);

  return {
    allPointList,
    loadMore,
  };
};
