import { useCreateVideoStreamMutation, useUpdateVideoStreamMutation } from 'domain/api/graphql/generated';
import {
  NOTIFICATION_DEFAULT_DELAY,
  NotificationColors,
  useNotificationContext,
} from 'application/providers/NotificationProvider';
import { useState } from 'react';
import { IDraggableVideoMediafile, IVideoMediafile, VideoStreamFlow } from '../types';
import { TEXTS } from '../texts';
import { getDefaultVideoStreamInput } from './utils';

interface UseCreateVideoStreamController {
  createVideoStream: ReturnType<typeof useCreateVideoStreamMutation>[0];
  updateVideoStream: ReturnType<typeof useUpdateVideoStreamMutation>[0];
  title?: string;
  description?: string;
  flow: VideoStreamFlow;
  selectedFiles: IVideoMediafile[];
  videoStreamId?: string;
  points?: { id: string }[];
  companyId: string;
}

export const useCreateVideoStreamController = ({
  createVideoStream,
  updateVideoStream,
  companyId,
  title = '',
  description = '',
  selectedFiles,
  flow,
  videoStreamId = '',
  points = [],
}: UseCreateVideoStreamController) => {
  const notification = useNotificationContext();

  const [mediafiles, setMediafiles] = useState<IDraggableVideoMediafile[]>(
    selectedFiles.map((it, i) => ({ ...it, id: `${it.id}-${i}` }))
  );

  const [videoStreamTitle, setVideoStreamTitle] = useState(title);
  const [videoStreamDescription, setVideoStreamDescription] = useState(description);

  const modalTitle = flow === VideoStreamFlow.CREATE ? TEXTS.CREATE_VIDEO_STREAM_TITLE : TEXTS.EDIT_VIDEO_STREAM_TITLE;

  const onCreate = async (title: string, description: string, videoMediafiles: IVideoMediafile[]) => {
    try {
      const commonInput = getDefaultVideoStreamInput(title, description, videoMediafiles, points);

      let newStreamId = videoStreamId;

      const input = {
        ...commonInput,
        companyId,
      };

      const { errors, data } = await createVideoStream({
        variables: {
          input,
        },
      });

      newStreamId = data?.result?.videoStream?.id || newStreamId;

      if (errors?.length) {
        throw new Error(errors[0].message);
      }

      return newStreamId;
    } catch (error) {
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: (error as Error).message,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    }

    return videoStreamId;
  };

  const onUpdate = async (title: string, description: string, videoMediafiles: IVideoMediafile[]) => {
    try {
      const commonInput = getDefaultVideoStreamInput(title, description, videoMediafiles, points);

      let newStreamId = videoStreamId;

      const input = {
        ...commonInput,
        videoStreamId,
      };

      const { errors, data } = await updateVideoStream({
        variables: {
          input,
        },
      });

      newStreamId = data?.result?.videoStream?.id || newStreamId;

      if (errors?.length) {
        throw new Error(errors[0].message);
      }

      return newStreamId;
    } catch (error) {
      notification.showNotification({
        type: NotificationColors.ERROR,
        children: (error as Error).message,
        delay: NOTIFICATION_DEFAULT_DELAY,
      });
    }

    return videoStreamId;
  };

  return {
    onSave: flow === VideoStreamFlow.CREATE ? onCreate : onUpdate,
    mediafiles,
    setMediafiles,
    videoStreamTitle,
    setVideoStreamTitle,
    videoStreamDescription,
    setVideoStreamDescription,
    modalTitle,
  };
};
